import axios, { AxiosInstance } from 'axios';

/**
 * Connect API URLs that should go to their original origin.
 * I.e. not use demo data for these URLs.
 */
const passthroughConnectApiUrls = [
  // No need to mock the user settings in demo mode.
  '/api/usersettings/me',
];

/**
 * Third party URLs that should be rewritten to use demo data.
 * These data are static files stored in the backend.
 */
const rewriteThirdPartyApiUrls = [
  // Authorization API.
  '/shared/authorizations/SAM_Connect/organizations/allowed/v2',
];

export const addDemoModeUrlRewriteInterceptor = (
  axiosInstance: AxiosInstance,
): void => {
  axiosInstance.interceptors.request.use((config) => {
    const isConnectApiAndShouldPassthroughRequest =
      passthroughConnectApiUrls.some((url) => config.url.endsWith(url));

    if (isConnectApiAndShouldPassthroughRequest) {
      // Passthrough the request, i.e. don't use demo data for these.
      return config;
    }

    const isConnectBackend = config.url.startsWith(
      `${window.config.connectBaseApiUrl}/api/`,
    );

    const isThirdPartyApiAndShouldUseDemoData = rewriteThirdPartyApiUrls.some(
      (url) => config.url.endsWith(url),
    );

    if (!(isConnectBackend || isThirdPartyApiAndShouldUseDemoData)) {
      // Do not rewrite URL.
      return config;
    }

    // Do rewrite URL to use demo data.
    try {
      const url = axios.getUri(config).startsWith('http')
        ? axios.getUri(config)
        : `${window.location.origin}${axios.getUri(config)}`;

      const { pathname, search } = new URL(url);

      return {
        ...config,
        url: `${window.config.connectBaseApiUrl}/api/demo-data`,
        params: {
          url: pathname + search,
        },
      };
    } catch (error) {
      console.error(
        `Could not rewrite URL for demo mode, URL [${config.url}].`,
        error,
      );
    }
  });
};
