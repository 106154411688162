import Button from '@storeblocks/button';
import React from 'react';

import { Permission } from '@/api/permissions/models/Permission';
import { useCurrentUserInfo } from '@/auth/hooks';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { useOrderSteps } from '@/pages/Order/components/OrderStepsProvider';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  isPending: boolean;
  canGoBack: boolean;
}

export const NavigationButtons: React.FC<Props> = ({
  isPending,
  canGoBack,
}) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();
  const hasPermission = useHasPermission();
  const userInfo = useCurrentUserInfo();

  const { goBack } = useOrderSteps();
  const { currentOrder, cancel } = useOrderPageContext();

  // Allow signing only if the current user is a signatory
  // and the current user has the orders sign permission.
  const canSign =
    currentOrder.signatories.includes(userInfo.userCmId) &&
    hasPermission(Permission.OrdersSign);

  const submitButtonText = canSign
    ? texts.orders.form.steps.buttons.goToSigning
    : texts.orders.form.steps.buttons.submit;

  return (
    <WithGap gap="8" direction="row">
      <Button
        type="submit"
        loading={isPending}
        disabled={isPending || !hasPermission(Permission.OrdersWrite)}
        data-test="submit-button"
        data-trackid={
          canSign
            ? getTrackId('go-to-signing', TrackingElement.Button)
            : getTrackId('submit-order', TrackingElement.Button)
        }
      >
        {submitButtonText}
      </Button>

      {/* Only the owner of the order can go back to edit it. */}
      {canGoBack && (
        <Button
          type="button"
          variant="outlined"
          onClick={goBack}
          data-test="back-button"
          data-trackid={getTrackId('go-back', TrackingElement.Button)}
        >
          {texts.orders.form.steps.buttons.back}
        </Button>
      )}

      <Button
        type="button"
        iconLeft="close"
        variant="text"
        onClick={cancel}
        data-test="cancel-button"
        data-trackid={getTrackId('cancel-order', TrackingElement.Button)}
      >
        {texts.orders.form.steps.buttons.cancel}
      </Button>
    </WithGap>
  );
};
