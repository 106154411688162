import Button from '@storeblocks/button';
import { SystemIcon } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { Show } from '@/components/Show';

import { DebugSurface } from './DebugSurface';
import { useCopyBearerToken } from './useCopyBearerToken';

export const CopyBearerToken: React.FC = () => {
  const { copyToClipboard, loading, success } = useCopyBearerToken();

  return (
    <DebugSurface>
      <p>Copy the bearer authentication token to your clipboard.</p>
      <Container>
        <Button
          onClick={() => copyToClipboard()}
          loading={loading}
          disabled={loading}
        >
          Copy to clipboard
        </Button>

        <Show when={success}>
          <SuccessIcon name="check-circle-filled" />
          <span>Copied!</span>
        </Show>
      </Container>
    </DebugSurface>
  );
};

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SuccessIcon = styled(SystemIcon)`
  color: var(--color-success-accent);
`;
