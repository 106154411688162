import Button from '@storeblocks/button';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useCurrentUserInfo } from '@/auth/hooks';
import { UserType } from '@/config/types';
import { isInternalUser, isLocalhost } from '@/config/utils';
import { setUserTypeToCookie } from '@/config/utils/setUserTypeToCookie';
import { shouldFetchConfigFromBackend } from '@/config/utils/shouldFetchConfigFromBackend';
import { useCopyBearerToken } from '@/pages/Debug/useCopyBearerToken';
import { isBetaFeaturesOn } from '@/util/isBetaFeaturesOn';
import { removeBetaFeaturesCookie } from '@/util/removeBetaFeaturesCookie';
import { setBetaFeaturesCookieOn } from '@/util/setBetaFeaturesCookieOn';

import { InternalLink } from '../InternalLink';
import { H3 } from '../Typography';
import { WithGap } from '../WithGap';
import { RoleManager } from './RoleManager';
import { ViewTokenData } from './ViewTokenData';

export const DevelopmentTools: FC = () => {
  const [showRoleModal, setShowRoleModal] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const { copyToClipboard, loading, success } = useCopyBearerToken();

  const organizationCmId = useOrganizationCmId();
  const userInfo = useCurrentUserInfo();

  const toggleDevMode = (): void => {
    if (isBetaFeaturesOn()) {
      removeBetaFeaturesCookie();
    } else {
      setBetaFeaturesCookieOn();
    }

    document.location.reload();
  };

  const toggleUserType = (): void => {
    setUserTypeToCookie(
      isInternalUser() ? UserType.External : UserType.Internal,
    );

    document.location.reload();
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Wrapper data-test="developer-tools">
        <WithGap gap="8">
          <Header>
            <H3>Developer Tools</H3>

            <Button
              variant="icon"
              iconLeft="close"
              onClick={() => setShow(false)}
            />
          </Header>

          <SignedInUser>
            <div>Signed in as</div>
            <div>{userInfo.name}</div>
            {userInfo.userCmId && <div>CMID {userInfo.userCmId}</div>}
          </SignedInUser>

          <StyledButton
            onClick={() => copyToClipboard()}
            variant="outlined"
            iconLeft="copy-paste"
            title="Copy the bearer authentication token to your clipboard."
            loading={loading}
            disabled={loading}
            data-success={success}
          >
            {success ? 'Copied!' : 'Copy token to clipboard'}
          </StyledButton>

          <ViewTokenData />

          <Button onClick={toggleDevMode} variant="outlined">
            Show beta content: {isBetaFeaturesOn() ? 'ON' : 'OFF'}
          </Button>

          {isLocalhost() && (
            <Button
              // Cannot toggle user type when config is fetched from backend.
              disabled={shouldFetchConfigFromBackend()}
              title={
                shouldFetchConfigFromBackend()
                  ? 'Cannot toggle user type when config is fetched from backend.'
                  : undefined
              }
              onClick={toggleUserType}
              variant="outlined"
            >
              {`User type: ${isInternalUser() ? 'INTERNAL' : 'EXTERNAL'}`}
            </Button>
          )}

          {organizationCmId && (
            <>
              <Button
                onClick={() => setShowRoleModal(true)}
                variant="outlined"
                data-test="role-manager-button"
              >
                Role manager
              </Button>
              <Button
                as={InternalLink}
                to={`${organizationCmId}/debug`}
                iconRight="arrow-right"
                variant="text"
              >
                Debug page
              </Button>
            </>
          )}
        </WithGap>
      </Wrapper>

      {organizationCmId && (
        /* The role manager modal. */
        <RoleManager
          show={showRoleModal}
          onClose={() => setShowRoleModal(false)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  padding: 20px;
  background: #fee6db;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignedInUser = styled.div`
  margin: 8px 0;
  line-height: 19px;
  font-size: 14px;

  > div:first-of-type {
    font-weight: 600;
  }
`;

const StyledButton = styled(Button)`
  transition: background-color 0.2s ease-in-out;

  &[data-success='true'] {
    background-color: var(--color-green-10);
  }
`;
