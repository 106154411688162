import Button from '@storeblocks/button';
import { decodeJwt } from 'jose';
import React, { useState } from 'react';
import { styled } from 'styled-components';

import { getAuth } from '@/auth/Auth';

/**
 * View the decoded token data.
 */
export const ViewTokenData: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [decodedToken, setDecodedToken] = useState<string | null>(null);

  const toggleIsOpen = async (): Promise<void> => {
    // ?: Is the view in progress to be opened?
    if (!isOpen) {
      // -> Yes, the view is in progress to be opened.
      const token = await getAuth().getToken();
      if (token) {
        setDecodedToken(decodeJwt(token));
      }
    }

    // Toggle the display state.
    setIsOpen((state) => !state);
  };

  return (
    <>
      {isOpen && <Token>{JSON.stringify(decodedToken, null, 2)}</Token>}

      <Button
        onClick={() => toggleIsOpen()}
        variant="outlined"
        iconLeft="drawer-file"
      >
        {isOpen ? 'Hide token data' : 'View token data'}
      </Button>
    </>
  );
};

const Token = styled.pre`
  background-color: #fff6f3;
  padding: 16px;
  border: 1px solid rgba(191, 9, 0, 0.5);
  font-size: 12px;
  max-height: 500px;
  overflow-y: auto;
`;
