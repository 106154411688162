import { Illustration } from '@storeblocks/icon';
import Surface from '@storeblocks/surface';
import { formatISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { useFmsTextsQuery } from '@/api/fms/fmsApi';
import { useUserSettingsQuery } from '@/api/userSettings/userSettingsApi';
import { getAuth } from '@/auth/Auth';
import { appConfig } from '@/constants/config';
import { Language } from '@/i18n/Language';
import { getSessionId } from '@/logging/sessionId';

import { Divider } from './Divider';
import { Column, Row } from './Grid';
import { PageHeading } from './PageHeading';
import { H3, Ingress, Paragraph, TextShortBold } from './Typography';
import { WithGap } from './WithGap';

export const UnexpectedError: React.FC = () => {
  const userSettingsQuery = useUserSettingsQuery();

  const fmsTextsQuery = useFmsTextsQuery(
    userSettingsQuery.data?.language ?? Language.English,
  );

  const texts = fmsTextsQuery.data;

  // Use hardcoded texts as fallback in case there is issues with FMS.
  const header =
    (texts && texts.error.header.general) || 'Something went wrong';

  const descriptionPart1 =
    (texts && texts.error.description.general.part1) ||
    'Please try again later, or contact customer service on email';

  const descriptionPart2 =
    (texts && texts.error.description.general.part2) ||
    'if the issue persists.';

  const siosEmail = (texts && texts.error.siosEmail) || 'sios@storebrand.no';

  const fieldsTitle =
    (texts && texts.error.field.title) || 'If you contact customer service...';
  const fieldsDescription =
    (texts && texts.error.field.description) ||
    'Please take a screenshot of or copy the text below and send it to customer service. This will help us identify the problem and help you even faster!';
  const errorCode = (texts && texts.error.errorCode.title) || 'Error code:';
  const userCmId = (texts && texts.error.field.userCmId) || 'User:';
  const organizationCmId =
    (texts && texts.error.field.organizationCmId) || 'Organization:';
  const url = (texts && texts.error.field.url) || 'Url:';
  const timestamp = (texts && texts.error.field.timestamp) || 'Timestamp:';

  let token;
  let sessionId = '';
  try {
    token = getAuth().getStaleTokenParsed();
    sessionId = getSessionId();
  } catch (e) {
    // Swallow the error since we don't want to throw
    // errors on the error page.
  }

  return (
    <Container data-test="error-page">
      <Center>
        <Page>
          <PageHeading header={header} />
          <Row>
            <Column $span={7}>
              <Ingress>
                {descriptionPart1}{' '}
                <a href={`mailto:${siosEmail}`}>{siosEmail}</a>{' '}
                {descriptionPart2}
              </Ingress>
            </Column>
          </Row>

          <Divider margin="8" />

          <StyledRow>
            <Column $span={6}>
              <StyledSurface>
                <WithGap gap="16">
                  <div>
                    <H3>{fieldsTitle}</H3>

                    <Paragraph>{fieldsDescription}</Paragraph>
                  </div>

                  <WithGap gap="8">
                    <div>
                      <TextShortBold>{errorCode}</TextShortBold>
                      <Paragraph>traceId:{sessionId}</Paragraph>
                    </div>

                    <div>
                      <TextShortBold>{timestamp}</TextShortBold>
                      <Paragraph>{formatISO(new Date())}</Paragraph>
                    </div>

                    {token?.cmId && (
                      <div>
                        <TextShortBold>{userCmId}</TextShortBold>
                        <Paragraph>{token.cmId}</Paragraph>
                      </div>
                    )}

                    {token?.signature && (
                      <div>
                        <TextShortBold>{userCmId}</TextShortBold>
                        <Paragraph>{token.signature}</Paragraph>
                      </div>
                    )}

                    {token?.behalfOf?.cmId && (
                      <div>
                        <TextShortBold>{organizationCmId}</TextShortBold>
                        <Paragraph>{token.behalfOf.cmId}</Paragraph>
                      </div>
                    )}

                    <div>
                      <TextShortBold>{url}</TextShortBold>
                      <LongParagraph>{location.href}</LongParagraph>
                    </div>
                  </WithGap>
                </WithGap>
              </StyledSurface>
            </Column>

            <StyledColumn $span={6}>
              <Illustration name="something-went-wrong" height="500" />
            </StyledColumn>
          </StyledRow>
        </Page>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  border-top: 3px solid var(--color-brand);
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const Page = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;

const StyledSurface = styled(Surface)`
  padding: 16px;
`;

const LongParagraph = styled(Paragraph)`
  overflow-wrap: break-word;
`;
