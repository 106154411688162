import { useState } from 'react';

import { getAuth } from '@/auth/Auth';

/**
 * Copy the bearer authentication token to your clipboard.
 *
 * Only the token is copied, not the "Bearer " prefix.
 *
 * To be used in combination with Postman or similar tools, or JWT.io to view the token data.
 */
export const useCopyBearerToken = (): {
  loading: boolean;
  success: boolean;
  copyToClipboard: () => void;
} => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const copyToClipboard = async (): Promise<void> => {
    setLoading(true);
    const token = await getAuth().getToken();
    await navigator.clipboard.writeText(token);
    setLoading(false);

    setSuccess(true);
    setTimeout(() => setSuccess(false), 5_000);
  };

  return {
    loading,
    success,
    copyToClipboard,
  };
};
