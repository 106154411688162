import { isCypress } from '@/config/utils';

import { cypressAuth } from './CypressAuth';
import { storebrandAuth } from './StorebrandAuth';

export interface Auth {
  initialize(): Promise<void>;
  getToken(): Promise<string>;
  getTokenParsed(): TokenParsed;
  getStaleTokenParsed(): TokenParsed | undefined;
  logout(): void;
  isAuthenticated(): boolean;
}

/**
 * The token parsed from the Keycloak token.
 *
 * Note: Add more fields as needed.
 */
export interface TokenParsed {
  /**
   * Full name of the user.
   */
  name: string;

  /**
   * The user's CMID.
   *
   * If a user is logged in via Storebrand SSO (e.g. an advisor) then the CMID will be `undefined`.
   */
  cmId: string | undefined;

  /**
   * The user's Storebrand signature.
   *
   * This will only be defined when a user is logged in via Storebrand SSO.
   * E.g. an advisor logs in to Connect.
   */
  signature: string | undefined;

  /**
   * The user's email address.
   * The field is blank for external users. Only internal users has their email exposed.
   */
  email: string | undefined;

  /**
   * The organization the user is logged in to.
   * This is only set when the user is logged in to a specific organization.
   */
  behalfOf?: {
    cmId: string;
  };
}

export function getAuth(): Auth {
  if (isCypress()) {
    return cypressAuth;
  }

  return storebrandAuth;
}
